export class Auth {
  step= 1
  show= false
  username = ''
  password = ''
  twoWayCode = ''
  loggedToken = false
  rememberme = 0
  email = ''
  success = false

  constructor (username = '', password = '') {
    this.username = username
    this.password = password
  }
}
