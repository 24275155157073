import EventBus from '@/util/EventBus'
import UserService from '@/services/user.service'

export default {
  name: 'LoginTwoFactor',
  props: {
    auth: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      manyAttempts: false,
    }
  },
  methods: {
    async handletwoWayCheck () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true
      this.$store.dispatch('auth/twoWayCheck', this.auth).then(
        () => {
          this.getSectionsBlocked()
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar de iniciar sesión'))
          }
          if (error.response.data.code === 'login.manyAttempts') {
            this.manyAttempts = true
            return false
          }

          if (error.response.status === 400) {
            EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
          }
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    getSectionsBlocked () {
      UserService.getSectionsBlocked().then(
        (response) => {
          this.sectionsBlocked(response)
          this.$router.push('/home')
        },
        (error) => {
          console.log(error)
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar de iniciar sesión'))
          }
          EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.error))
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    sectionsBlocked (sectionsBlockeds) {
      const data = []
      for (const sectionBlock of sectionsBlockeds) {
        data.push(sectionBlock.toLowerCase())
      }
      localStorage.setItem('sectionsBlockeds', JSON.stringify(data))
    },
  },
}
