import { Auth } from '@/models/auth'
import { RecoverPasswordData } from '@/models/RecoverPasswordData'
import AccountService from '@/services/account.service.js'
import Alert from '@/components/Alert/Alert.vue'
import LoginForm from './LoginFom/LoginForm.vue'
import LoginTwoFactor from './LoginTwoFactor/LoginTwoFactor.vue'
import { Languages } from '../../../../classes/Languages'
import { ValidatorLocalize } from '@/util/ValidatorLocalize'

export default {
  name: 'Login',
  components: {
    Alert,
    LoginForm,
    LoginTwoFactor,
  },
  data () {
    return {
      auth: new Auth(),
      forgotPasswordDialog: false,
      showSuccessSendLink: true,
      recoverPasswordData: new RecoverPasswordData(),
      loadRecover: false,
      hoverForgotButton: false,
      hoverRegisterButton: false,
      languages: Languages.get(),
      registerURL: {
        es: 'https://360nrs.com/registro',
        en: 'https://en.360nrs.com/register',
        pt: 'https://pt.360nrs.com/inscricao',
        it: 'https://it.360nrs.com/registrati',
        default: 'https://en.360nrs.com/register',
      },
    }
  },
  methods: {
    showForgotPasswordDialog () {
      this.showSuccessSendLink = false
      this.forgotPasswordDialog = true
      this.resetRecoverForm()
    },
    sendLink () {
      this.loadRecover = true
      AccountService.sendLink(this.recoverPasswordData)
      .then(
        () => {
          this.resetRecoverForm()
        },
      )
      .finally(() => {
        this.loadRecover = false
        this.showSuccessSendLink = true
        setTimeout(() => {
          this.forgotPasswordDialog = false
          this.showSuccessSendLink = false
        }, 3000)
      })
    },
    resetRecoverForm () {
      this.recoverPasswordData.clear()
      this.$nextTick(() => this.$refs.recoverPassword.reset())
    },
    changeLanguage (langIsoCode) {
      this.userLanguage = langIsoCode
      this.$i18n.locale = langIsoCode
      localStorage.setItem('language', langIsoCode)
      ValidatorLocalize.setLanguage(langIsoCode)
    },
    setIsoFlag (isoCode) {
      const lang = isoCode === 'en' ? 'gb' : isoCode
      return require('./../../../../assets/img/flags/' + lang + '.svg')
    },
    goToRegister () {
      const url = this.registerURL[this.$i18n.locale] || this.registerURL.default
      window.location.replace(url)
    },
  },
  created () {
    this.$i18n.locale = this.$route.query.lang || this.$i18n.locale
  },
  mounted () {
    this.userLanguage = this.$i18n.locale.split('-')[0]
    const langIsoCode = localStorage.getItem('language') || this.userLanguage
    ValidatorLocalize.setLanguage(langIsoCode)
  },
}
