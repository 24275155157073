import EventBus from '@/util/EventBus'
import UserService from '@/services/user.service'

export default {
  name: 'LoginForm',
  props: {
    auth: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      loading: false,
      showPassword: false,
    }
  },
  methods: {
    append () {
      this.showPassword = !this.showPassword
    },
    async handleLogin () {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        return
      }

      this.loading = true
      this.$store.dispatch('auth/login', this.auth).then(
        (response) => {
          this.auth.loggedToken = response.logged_token
          if (response.token) {
            this.getSectionsBlocked()
            return
          }

          if (Object.keys(response).includes('two_way_code') && response.two_way_code === true) {
            this.auth.step = 2
            this.auth.email = response.email
          }
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar de iniciar sesión'))
          }
          if (error.response.status === 400) {
            EventBus.$emit('showAlert', 'danger', this.$errorMessages.getMessage(error.response.data.code))
          }
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    getSectionsBlocked () {
      UserService.getSectionsBlocked().then(
        (response) => {
          this.sectionsBlocked(response)
          this.$router.push('/home')
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar de iniciar sesión'))
          }
          EventBus.$emit('showAlert', 'danger', this.$t(error.response.data.error))
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    sectionsBlocked (sectionsBlockeds) {
      const data = []
      for (const sectionBlock of sectionsBlockeds) {
        data.push(sectionBlock.toLowerCase())
      }
      localStorage.setItem('sectionsBlockeds', JSON.stringify(data))
    },
  },
  created () {
    const autoLoginToken = this.$route.query.autologinToken

    if (autoLoginToken) {
      this.loading = true
      this.$store.dispatch('auth/autologin', { token: autoLoginToken }).then(
        (response) => {
          console.log(response)
          this.$router.push('/home')
        },
        (error) => {
          if (error.response.status === 422) {
            EventBus.$emit('showAlert', 'danger', this.$t('Error al intentar autologin'))
          }
        },
      )
      .finally(() => {
        this.loading = false
      })
    }
  },
}
